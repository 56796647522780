import Backdrop from "@material-ui/core/Backdrop";
import { Input } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { Link } from "react-router-dom";
import BlockIcon from "@material-ui/icons/Block";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Clear from "@material-ui/icons/Clear";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import SendIcon from "@material-ui/icons/Send";
import ViewColumn from "@material-ui/icons/ViewColumn";
import MaterialTable from "material-table";
import React, { forwardRef, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getUserTypes, updateRestrictions } from "../../services/user.service";
import { displayMessage } from "./../common/common";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <BlockIcon {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
function validateField(val) {
  return val != "";
}

const Restrictions = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [backDropOpen, setBackDropOpen] = useState(true);
  const columns = [
    { title: "Name", field: "type_name", editable: "never" },
    {
      title: "Share Links",
      field: "shareLink",
      lookup: {
        [false]: "No",
        [true]: "Yes",
      },
      render: (rowData) => {
        return rowData.shareLink ? <CheckCircleIcon /> : <CancelIcon />;
      },
      editable: "onUpdate",
    },
    {
      title: "Sharelink embed/qr",
      field: "shareLinkQr",
      lookup: {
        [false]: "No",
        [true]: "Yes",
      },
      render: (rowData) => {
        return rowData.shareLinkQr ? <CheckCircleIcon /> : <CancelIcon />;
      },
      editable: "onUpdate",
    },
    {
      title: "White Label",
      field: "whiteLabel",
      lookup: {
        [false]: "No",
        [true]: "Yes",
      },
      render: (rowData) => {
        return rowData.whiteLabel ? <CheckCircleIcon /> : <CancelIcon />;
      },
      editable: "onUpdate",
    },
    {
      title: "GeoLocated Ads",
      field: "geoLocatedAds",
      lookup: {
        [false]: "No",
        [true]: "Yes",
      },
      render: (rowData) => {
        return rowData.geoLocatedAds ? <CheckCircleIcon /> : <CancelIcon />;
      },
      editable: "onUpdate",
    },
    {
      title: "Storage",
      field: "storageCapacity",
      editComponent: (props) => (
        <Input
          type="number"
          inputProps={{ min: 0 }}
          defaultValue={props.value / 1024}
          onChange={(e) => props.onChange(e.target.value)}
        />
      ),
      type: "numeric",
      render: (col) => {
        return col.storageCapacity
          ? (col.storageCapacity / 1024).toFixed(2) + " GB"
          : "";
      },
      editable: "onUpdate",
    },
    {
      title: "Max Image",
      field: "imageMax",
      editComponent: (props) => (
        <Input
          type="number"
          inputProps={{ min: 0 }}
          defaultValue={props.value}
          onChange={(e) => props.onChange(e.target.value)}
        />
      ),
      type: "numeric",
      render: (col) => {
        return col.imageMax ? col.imageMax + " MP" : "";
      },
      editable: "onUpdate",
    },
    {
      title: "Max Upload",
      field: "maxUpload",
      editComponent: (props) => (
        <Input
          type="number"
          inputProps={{ min: 0 }}
          defaultValue={props.value / 1024}
          onChange={(e) => props.onChange(e.target.value)}
        />
      ),
      type: "numeric",
      render: (col) => {
        return col.maxUpload ? (col.maxUpload / 1024).toFixed(2) + " GB" : "";
      },
      editable: "onUpdate",
    },
    {
      title: "360 Max",
      field: "threeSixtyMax",

      editComponent: (props) => (
        <Input
          type="number"
          inputProps={{ min: 0 }}
          defaultValue={props.value / 1024}
          onChange={(e) => props.onChange(e.target.value)}
        />
      ),
      type: "numeric",
      render: (col) => {
        return col.threeSixtyMax
          ? (col.threeSixtyMax / 1024).toFixed(2) + " GB"
          : "";
      },
      editable: "onUpdate",
    },
    {
      title: "Support Type",
      field: "supportForums",
      lookup: {
        forums: "forums",
        "email/chat": "email/chat",
        "email/chat/phone": "email/chat/phone",
      },
    },
  ];

  useEffect(() => {
    getUserTypes().then((res) => {
      setData(
        res.data.userTypes.rows.sort(function(a, b) {
          if (a.type_name < b.type_name) {
            return -1;
          }
          if (a.type_name > b.type_name) {
            return 1;
          }
          return 0;
        })
      );
      setBackDropOpen(false);
    });
  }, []);

  return (
    <div className="margindiv usersTable">
      <MaterialTable
        localization={{
          body: {
            deleteTooltip: "Deactivate",
          },
        }}
        options={{
          exportButton: true,
          exportAllData: true,
        }}
        icons={tableIcons}
        title="Restrictions"
        columns={columns}
        data={data}
        editable={{
          onRowUpdate: (newData, oldData) => {
            return new Promise((resolve, reject) => {
              let datatosend = {
                typeName: newData.type_name,
                storageCapacity:
                  oldData.storageCapacity == newData.storageCapacity
                    ? oldData.storageCapacity
                    : newData.storageCapacity * 1024,
                freeDataLibrary: newData.freeDataLibrary,
                paidDataLibrary: newData.paidDataLibrary,
                hostPublicData: newData.hostPublicData,
                canCreateOrganization: newData.canCreateOrganization,
                organizationLimit: newData.organizationLimit,
                shareLink: JSON.parse(newData.shareLink),
                shareLinkQr: JSON.parse(newData.shareLinkQr),
                whiteLabel: JSON.parse(newData.whiteLabel),
                geoLocatedAds: JSON.parse(newData.geoLocatedAds),
                supportForums: newData.supportForums,
                maxUpload:
                  oldData.maxUpload == newData.maxUpload
                    ? oldData.maxUpload
                    : newData.maxUpload * 1024,
                imageMax: JSON.parse(newData.imageMax),
                threeSixtyMax: newData.threeSixtyMax
                  ? oldData.threeSixtyMax == newData.threeSixtyMax
                    ? oldData.threeSixtyMax
                    : newData.threeSixtyMax * 1024
                  : 0,
              };
              updateRestrictions(newData.type_name, datatosend).then((res) => {
                const dataUpdate = [...data];
                const index = oldData.tableData.id;
                newData.storageCapacity =
                  oldData.storageCapacity == newData.storageCapacity
                    ? oldData.storageCapacity
                    : newData.storageCapacity * 1024;
                newData.maxUpload =
                  oldData.maxUpload == newData.maxUpload
                    ? oldData.maxUpload
                    : newData.maxUpload * 1024;
                newData.threeSixtyMax =
                  oldData.threeSixtyMax == newData.threeSixtyMax
                    ? oldData.threeSixtyMax
                    : newData.threeSixtyMax * 1024;
                dataUpdate[index] = newData;
                setData([...dataUpdate]);

                resolve();
                dispatch(
                  displayMessage("success", "Data updated successfully")
                );
              });
            });
          },
        }}
      />
      <Backdrop
        open={backDropOpen}
        style={{ zIndex: 99999999999, color: "#fff" }}
      >
        <CircularProgress />
      </Backdrop>
    </div>
  );
};

export default Restrictions;
