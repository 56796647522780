import { IconButton, Tab, Tabs } from "@material-ui/core/";
import Button from "@material-ui/core/Button";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import CancelIcon from "@material-ui/icons/Cancel";
import Check from "@material-ui/icons/Check";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import SendIcon from "@material-ui/icons/Send";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import MaterialTable, { MTableAction } from "material-table";
import secureLocalStorage from "react-secure-storage";
import React, { forwardRef, useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  createOrganization,
  deletePending,
  deleteUserOrganizaton,
  getOrganization,
  getPendingInvites,
  inviteOrganizationUser,
  updateOrganization,
  updateOrganizationAdmin,
} from "../../services/user.service";
import { LOADING_MESSAGE, SET_MESSAGE } from "./../../actions/types";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { allowedUser, displayMessage } from "./../common/common";
import { GithubPicker } from "react-color";
import { useHistory } from "react-router-dom";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
function validateField(val) {
  return val != "";
}
const Organization = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  const { isOrganizationAdmin } = useSelector((state) => state.auth);
  const { message, isLoading } = useSelector(
    (state) => state.message,
    shallowEqual
  );
  const [pending, setPending] = useState([]);
  const [data, setData] = useState([]);
  const typeID = JSON.parse(secureLocalStorage.getItem("typeID"));
  const [formData, setFormData] = useState({
    organizationName: "",
    organizationDescription: "",
    logoUrl: "",
    navbarColorCode: "",
    header: "",
    text: "",
  });

  const changeInputHandler = (e) => {
    const { name, value, checked, type } = e.target;
    setFormData({ ...formData, [name]: type === "checkbox" ? checked : value });
  };
  const columns = [
    {
      title: "First Name",
      field: "firstName",
      editable: "onAdd",
      validate: (rowData) => {
        if (validateField(rowData.firstName)) {
        }

        return validateField(rowData.firstName) && rowData.firstName
          ? ""
          : "First name is required";
      },
    },
    {
      title: "Last Name",
      field: "lastName",
      editable: "onAdd",
      validate: (rowData) => {
        if (validateField(rowData.lastName)) {
        }

        return validateField(rowData.lastName) && rowData.lastName
          ? ""
          : "Last name is required";
      },
    },
    {
      title: "Email",
      field: "email",
      editable: "onAdd",
      validate: (rowData) => {
        if (validateEmail(rowData.email)) {
        }
        return validateEmail(rowData.email) ? "" : "Email is invalid";
      },
    },
    {
      title: "Admin Status",
      field: "isOrganizationAdmin",
      lookup: {
        [false]: "No",
        [true]: "Yes",
      },
      render: (rowData) => {
        return rowData.isOrganizationAdmin ? (
          <CheckCircleIcon />
        ) : (
          <CancelIcon />
        );
      },
      editable: "onUpdate",
      // validate: (rowData) => {
      //   if (validateField(rowData.lastName)) {
      //   }

      //   return validateField(rowData.lastName) && rowData.lastName
      //     ? ""
      //     : "Last name is required";
      // },
    },
    {
      title: "Active Status",
      field: "active",
      lookup: {
        [false]: "Inactive",
        [true]: "Active",
      },
      render: (rowData) => {
        return rowData?.active ? <CheckCircleIcon /> : <CancelIcon />;
      },
      editable: (e, rowData) => {
        return rowData ? (rowData?.active ? null : "onUpdate") : null;
      },
      // validate: (rowData) => {
      //   if (validateField(rowData.lastName)) {
      //   }

      //   return validateField(rowData.lastName) && rowData.lastName
      //     ? ""
      //     : "Last name is required";
      // },
    },
  ];
  const columnsPending = [
    {
      title: "First Name",
      field: "user.firstName",
      editable: "onAdd",
      validate: (rowData) => {
        if (validateField(rowData.user.firstName)) {
        }

        return validateField(rowData.user.firstName) && rowData.user.firstName
          ? ""
          : "First name is required";
      },
    },
    {
      title: "Last Name",
      field: "user.lastName",
      editable: "onAdd",
      validate: (rowData) => {
        if (validateField(rowData.user.lastName)) {
        }

        return validateField(rowData.user.lastName) && rowData.user.lastName
          ? ""
          : "Last name is required";
      },
    },
    {
      title: "Email",
      field: "user.email",
      editable: "onAdd",
      validate: (rowData) => {
        if (validateEmail(rowData.user.email)) {
        }
        return validateEmail(rowData.user.email) ? "" : "Email is invalid";
      },
    },
    {
      title: "Resend",
      field: "Action",
      render: (rowData) => {
        return (
          <IconButton
            onClick={() => {
              inviteOrganizationUser(
                rowData.user.email,
                JSON.parse(secureLocalStorage.getItem("organizationID")),
                rowData.user.firstName ? rowData.user.firstName : "",
                rowData.user.lastName ? rowData.user.lastName : ""
              )
                .then((res) => {
                  dispatch(
                    displayMessage(
                      "success",
                      rowData.user.firstName
                        ? "Invite resent successfully to " +
                            rowData.user.firstName +
                            " " +
                            rowData.user.lastName
                        : "Invite resent successfully"
                    )
                  );
                })
                .catch(() => {
                  dispatch(
                    displayMessage(
                      "error",
                      "This user is not allowed to join the Team!"
                    )
                  );
                });
            }}
          >
            <SendIcon />
          </IconButton>
        );
      },
    },
  ];

  const handleOrganization = (e) => {
    e.preventDefault();
    dispatch({ type: LOADING_MESSAGE, isLoading: true });
    let formDataBody = new FormData();
    formDataBody.append("organizationName", formData.organizationName);
    formDataBody.append(
      "organizationDescription",
      formData.organizationDescription
    );
    if (formData.file) {
      formDataBody.append("file", formData.file);
      secureLocalStorage.setItem(
        "organizationLogo",
        formData.logoUrl ? formData.logoUrl : ""
      );
      if (JSON.parse(secureLocalStorage.getItem("restrictions"))?.whiteLabel) {
        document.getElementById("companyLogo1").src = formData.logoUrl;
        document.getElementById("companyLogo1").style.height = "43px";
        document.getElementById("companyLogo1").style.width = "auto";
      } else {
        document.getElementById("companyLogo").src = formData.logoUrl;
        document.getElementById("companyLogo").style.display = "";
      }
    } else if (formData.file == "remove") {
      formDataBody.append("file", "remove");
    }
    // if (formData.header || formData.text) {
    secureLocalStorage.setItem(
      "organizationColor",
      formData.header ? formData.header : ""
    );
    secureLocalStorage.setItem(
      "organizationText",
      formData.text ? formData.text : ""
    );
    formDataBody.append(
      "style",
      JSON.stringify({
        header: formData.header ? formData.header : "",
        text: formData.text ? formData.text : "",
      })
    );
    // }
    if (JSON.parse(secureLocalStorage.getItem("organizationID"))) {
      updateOrganization(
        formDataBody,
        JSON.parse(secureLocalStorage.getItem("organizationID"))
      )
        .then((res, error) => {
          dispatch({ type: LOADING_MESSAGE, isLoading: false });
          secureLocalStorage.setItem(
            "organizationName",
            formData.organizationName
          );
          if (error) {
            dispatch(
              displayMessage(
                "error",
                error.response
                  ? error.response.data.message
                  : "Team update error"
              )
            );
          }
        })
        .catch((error) => {
          dispatch(
            displayMessage(
              "error",
              error.response ? error.response.data.message : "Team update error"
            )
          );
        });
    } else {
      createOrganization(formDataBody)
        .then((res) => {
          secureLocalStorage.removeItem("organizationName");
          secureLocalStorage.removeItem("organizationID");
          secureLocalStorage.removeItem("organizationLimit");
          secureLocalStorage.setItem(
            "organizationName",
            formData.organizationName
          );
          secureLocalStorage.setItem("isOrganizationAdmin", 1);
          secureLocalStorage.setItem(
            "organizationID",
            res.data.organization.organizationID
          );
          secureLocalStorage.setItem(
            "organizationLimit",
            res.data.organization.limit
          );
          dispatch({ type: LOADING_MESSAGE, isLoading: false });
        })
        .catch((error) => {
          dispatch(
            displayMessage(
              "error",
              error.response ? error.response.data.message : "Team create error"
            )
          );
        });
    }
  };

  useEffect(() => {
    dispatch({ type: LOADING_MESSAGE, isLoading: true });

    if (JSON.parse(secureLocalStorage.getItem("organizationID"))) {
      getPendingInvites(
        JSON.parse(secureLocalStorage.getItem("organizationID"))
      ).then((res) => {
        setPending(res.data.invites);
      });
      getOrganization(JSON.parse(secureLocalStorage.getItem("organizationID")))
        .then((res, err) => {
          if (
            typeof JSON.parse(res.data.organization.style) === "object" &&
            JSON.parse(res.data.organization.style)
          ) {
            res.data.organization.header = JSON.parse(
              res.data.organization.style
            ).header;
            res.data.organization.text = JSON.parse(
              res.data.organization.style
            ).text;
          } else {
            res.data.organization.header = "";
            res.data.organization.text = "";
          }

          setFormData({ ...formData, ...res.data.organization });
          setData(res.data.organizationUsers.rows);
          dispatch({ type: LOADING_MESSAGE, isLoading: false });
        })
        .catch((error) => {
          dispatch(
            displayMessage(
              "error",
              error.response ? error.response.data.message : message
            )
          );
        });
    } else {
      dispatch({ type: LOADING_MESSAGE, isLoading: false });
    }
    sessionStorage.setItem("reloadMap", 1);
  }, []);
  const addActionRef = React.useRef();
  return (
    <div className="margindiv usersTable">
      {secureLocalStorage.getItem("isOrganizationAdmin") ||
      allowedUser.includes(typeID) ? (
        <>
          {/* <Tabs
            value={value}
            style={{ marginBottom: 15 }}
            onChange={(e, val) => {
              setValue(val);
            }}
          >
            <Tab label="Team Info" />
            <Tab
              disabled={
                !JSON.parse(secureLocalStorage.getItem("organizationID"))
              }
              label="Users"
            />
          </Tabs> */}

          {/* <TabPanel value={value} index={0}> */}
          <div style={{ marginBottom: 50 }}>
            <h1
              style={{
                textAlign: "center",
                background: "#901da2",
                color: "white",
              }}
            >
              Team Info
            </h1>
            <ValidatorForm className="FormStyle" onSubmit={handleOrganization}>
              <div style={{ textAlign: "center" }}>
                <img
                  style={{ width: "150px", borderRadius: "30px" }}
                  src={formData.logoUrl}
                ></img>
              </div>
              <div style={{ marginBottom: 15 }}>
                <label>Team Name</label>
                <TextValidator
                  validators={["required"]}
                  errorMessages={["this field is required"]}
                  name="organizationName"
                  value={formData.organizationName}
                  onChange={changeInputHandler}
                  type="text"
                  className="form-control"
                  placeholder="Team name"
                />
              </div>
              <div style={{ marginBottom: 15 }}>
                <label>Team Description</label>
                <TextValidator
                  validators={["required"]}
                  errorMessages={["this field is required"]}
                  name="organizationDescription"
                  value={formData.organizationDescription}
                  onChange={changeInputHandler}
                  type="text"
                  className="form-control"
                  placeholder="Team description"
                />
              </div>
              <div style={{ marginBottom: 15 }}>
                <label>Organization Logo</label>
                <div style={{ width: "150px" }}>
                  <input
                    type="file"
                    name="logoUrl"
                    accept={".jpg,.png"}
                    onChange={(event) => {
                      // let files = [...event.target.files];
                      let file = event.target.files[0];
                      if (file) {
                        var reader = new FileReader();
                        reader.onload = function() {
                          setFormData({
                            ...formData,
                            logoUrl: reader.result,
                            file: file,
                          });
                        };
                        reader.readAsDataURL(file);
                      }
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  marginBottom: 15,
                  display: JSON.parse(
                    secureLocalStorage.getItem("restrictions")
                  )?.whiteLabel
                    ? ""
                    : "none",
                }}
              >
                <label>
                  Select navbar color
                  {/* <input
                    style={{ marginLeft: 15 }}
                    type="color"
                    id="favcolor"
                    name="navbarColorCode"
                    value={formData.navbarColorCode}
                    onChange={(val) => {
                      document.getElementsByClassName(
                        "header-main"
                      )[0].style.background = val.currentTarget.value;
                      // secureLocalStorage.setItem(
                      //   "organizationColor",
                      //   val.currentTarget.value
                      // );
                      // changeInputHandler(val)
                    }}
                    // onChange={changeInputHandler}
                  /> */}
                  <GithubPicker
                    colors={[
                      "#B80000",
                      "#901da1",
                      "#DB3E00",
                      "#FCCB00",
                      "#008B02",
                      "#006B76",
                      "#1273DE",
                      "#004DCF",
                      "#5300EB",
                      "#000000",
                      "#5AAAAB",
                    ]}
                    width={287}
                    // color={polygonBackground}
                    // onChangeComplete={handleChangeComplete}
                    onChangeComplete={(val) => {
                      document.getElementsByClassName(
                        "header-main"
                      )[0].style.background = val.hex;
                      setFormData({ ...formData, header: val.hex });
                      // secureLocalStorage.setItem(
                      //   "organizationColor",
                      //   val.currentTarget.value
                      // );
                      // changeInputHandler(val)
                    }}
                    disableAlpha={true}
                  />
                </label>
              </div>
              <div
                style={{
                  marginBottom: 15,
                  display: JSON.parse(
                    secureLocalStorage.getItem("restrictions")
                  )?.whiteLabel
                    ? ""
                    : "none",
                }}
              >
                <label>
                  Select navbar text color
                  {/* <input
                    style={{ marginLeft: 15 }}
                    type="color"
                    id="favcolor"
                    name="navbarColorCode"
                    value={formData.navbarColorCode}
                    onChange={(val) => {
                      Array.prototype.forEach.call(
                        document.getElementsByClassName("nav-link"),
                        function(item) {
                          item.style.color = val.currentTarget.value;
                        }
                      );

                      // changeInputHandler(val)
                      // secureLocalStorage.setItem(
                      //   "organizationColor",
                      //   val.currentTarget.value
                      // );
                    }}
                    // onChange={changeInputHandler}
                  /> */}
                  <GithubPicker
                    colors={[
                      "#B80000",
                      "#FFFFFF",
                      "#DB3E00",
                      "#FCCB00",
                      "#008B02",
                      "#006B76",
                      "#1273DE",
                      "#004DCF",
                      "#5300EB",
                      "#000000",
                      "#5AAAAB",
                    ]}
                    width={287}
                    onChangeComplete={(val) => {
                      Array.prototype.forEach.call(
                        document.getElementsByClassName("nav-link"),
                        function(item) {
                          item.style.color = val.hex;
                        }
                      );
                      setFormData({ ...formData, text: val.hex });
                      // changeInputHandler(val)
                      // secureLocalStorage.setItem(
                      //   "organizationColor",
                      //   val.currentTarget.value
                      // );
                    }}
                    disableAlpha={true}
                  />
                </label>
              </div>
              <div>
                <Button
                  style={{ width: "22%" }}
                  type="submit"
                  variant="contained"
                  className="btn-block btnTheme"
                >
                  Save Team
                </Button>
                <Button
                  style={{ width: "22%", left: "1%" }}
                  onClick={() => {
                    setFormData({
                      ...formData,
                      header: null,
                      text: null,
                      logoUrl: "/images/logo.png",
                      file: "remove",
                    });
                    document.getElementsByClassName(
                      "header-main"
                    )[0].style.background = "#901da1";
                    Array.prototype.forEach.call(
                      document.getElementsByClassName("nav-link"),
                      function(item) {
                        item.style.color = "#ffffff";
                      }
                    );
                    if (
                      JSON.parse(secureLocalStorage.getItem("restrictions"))
                        ?.whiteLabel
                    ) {
                      document.getElementById("companyLogo1").src =
                        "/images/logo.png";
                    } else {
                      document.getElementById("companyLogo").style.display =
                        "none";
                    }
                  }}
                  variant="contained"
                  className="btn-block btnTheme"
                >
                  Reset UI
                </Button>
                <Button
                  style={{ width: "22%", left: "2%" }}
                  onClick={() => {
                    history.push("/manage-plan");
                  }}
                  variant="contained"
                  className="btn-block btnTheme"
                >
                  Manage Subscriptions
                </Button>
                <Button
                  style={{ width: "22%", left: "3%" }}
                  onClick={() => {
                    history.push("/org-tags");
                  }}
                  variant="contained"
                  className="btn-block btnTheme"
                >
                  Organization Tags
                </Button>
              </div>
            </ValidatorForm>
          </div>
          {/* </TabPanel> */}
          {/* <TabPanel value={value} index={1}> */}
          {pending.length ? (
            <>
              <h1
                style={{
                  textAlign: "center",
                  background: "#901da2",
                  color: "white",
                }}
              >
                Pending Invites
              </h1>
              <div
                style={{
                  marginBottom: 40,
                  display: "inline-block",
                  width: "100%",
                }}
              >
                <MaterialTable
                  localization={{
                    body: {
                      addTooltip: "Invite",
                    },
                  }}
                  options={{
                    pageSize: 20,
                    emptyRowsWhenPaging: false,
                    exportButton: true,
                    exportAllData: true,
                  }}
                  icons={tableIcons}
                  components={{
                    Action: (props) => {
                      //If isn't the add action
                      if (
                        typeof props.action === typeof Function ||
                        props.action.tooltip !== "Invite"
                      ) {
                        return <MTableAction {...props} />;
                      } else {
                        return (
                          <div
                            ref={addActionRef}
                            onClick={props.action.onClick}
                          />
                        );
                      }
                    },
                  }}
                  columns={columnsPending}
                  data={pending}
                  title=""
                  editable={{
                    onRowDelete: (oldData) =>
                      new Promise((resolve, reject) => {
                        deletePending(oldData.id).then((res) => {
                          const dataDelete = [...pending];
                          const index = oldData.tableData.id;
                          dataDelete.splice(index, 1);
                          setPending([...dataDelete]);
                          resolve();
                          dispatch(
                            displayMessage(
                              "success",
                              "Invite removed successfully"
                            )
                          );
                        });
                      }),
                  }}
                />
              </div>
            </>
          ) : null}
          <h1
            style={{
              textAlign: "center",
              background: "#901da2",
              color: "white",
            }}
          >
            Users
          </h1>
          <div
            style={{ marginBottom: 40, display: "inline-block", width: "100%" }}
          >
            <MaterialTable
              localization={{
                body: {
                  addTooltip: "Invite",
                },
              }}
              options={{
                pageSize: 20,
                emptyRowsWhenPaging: false,
                exportButton: true,
                exportAllData: true,
              }}
              icons={tableIcons}
              components={{
                Action: (props) => {
                  //If isn't the add action
                  if (
                    typeof props.action === typeof Function ||
                    props.action.tooltip !== "Invite"
                  ) {
                    return <MTableAction {...props} />;
                  } else {
                    return (
                      <div ref={addActionRef} onClick={props.action.onClick} />
                    );
                  }
                },
              }}
              title={
                <div>
                  <h6
                    class="MuiTypography-root MuiTypography-h6"
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "inline",
                    }}
                  >
                    {secureLocalStorage.getItem("organizationLimit") &&
                    secureLocalStorage.getItem("organizationLimit") !==
                      "undefined"
                      ? `Users ${data.length -
                          data.filter((item) => !item.active).length}/${Number(
                          secureLocalStorage.getItem("organizationLimit")
                        )}`
                      : "No Team"}
                  </h6>
                  <Button
                    onClick={() => addActionRef.current.click()}
                    style={{ margin: 10, width: 125 }}
                    variant="contained"
                    className="btn-block btnTheme addLink"
                    autoFocus
                  >
                    Add User
                  </Button>
                </div>
              }
              columns={columns}
              data={data}
              editable={{
                onRowAdd: (newData) => {
                  return new Promise((resolve, reject) => {
                    if (
                      data.length - data.filter((item) => !item.active).length <
                      Number(secureLocalStorage.getItem("organizationLimit"))
                    ) {
                      if (validateEmail(newData.email)) {
                        inviteOrganizationUser(
                          newData.email,
                          JSON.parse(
                            secureLocalStorage.getItem("organizationID")
                          ),
                          newData.firstName ? newData.firstName : "",
                          newData.lastName ? newData.lastName : ""
                        )
                          .then((res) => {
                            // newData["id"] = res.data.user.id;
                            // newData["uuid"] = res.data.user.uuid;
                            // newData["isVerified"] = 0;
                            // newData["typeID"] = 2;
                            // setData([...data, newData]);

                            resolve();
                            getPendingInvites(
                              JSON.parse(
                                secureLocalStorage.getItem("organizationID")
                              )
                            ).then((res) => {
                              setPending(res.data.invites);
                            });
                            dispatch(
                              displayMessage(
                                "success",
                                newData.firstName
                                  ? "Invite sent successfully to new user"
                                  : "Invite sent successfully"
                              )
                            );
                          })
                          .catch(() => {
                            resolve();
                            dispatch(
                              displayMessage(
                                "error",
                                "This user is not allowed to join the Team!"
                              )
                            );
                          });
                      } else {
                        reject();
                      }
                    } else {
                      dispatch(
                        displayMessage(
                          "error",
                          "Your user limit has been reached, please contact sales@metageo.io to upgrade"
                        )
                      );
                      reject();
                    }
                  });
                },
                onRowUpdate: (newData, oldData) => {
                  return new Promise((resolve, reject) => {
                    // newData.firstName,
                    // newData.lastName,
                    // newData.location,
                    // // newData.age,
                    // // null,
                    // newData.company,
                    // newData.typeID,
                    // oldData.uuid,
                    // newData.isVerified,
                    // newData.password && newData.password

                    updateOrganizationAdmin(
                      JSON.parse(secureLocalStorage.getItem("organizationID")),
                      oldData.uuid,
                      {
                        active: newData.active,
                        isOrganizationAdmin: newData.isOrganizationAdmin,
                      }
                    )
                      .then((res) => {
                        if (res?.data?.status === 200) {
                          const dataUpdate = [...data];
                          const index = oldData.tableData.id;
                          dataUpdate[index] = newData;
                          setData([...dataUpdate]);
                          resolve();
                          dispatch(
                            displayMessage(
                              "success",
                              "User updated successfully"
                            )
                          );
                        } else {
                          dispatch(
                            displayMessage(
                              "error",
                              res?.data?.message || "User cannot updated"
                            )
                          );
                          reject();
                        }
                      })
                      .catch((error) => {
                        dispatch(
                          displayMessage("error", "User cannot updated")
                        );
                        reject();
                      });
                  });
                },
                onRowDelete: (oldData) =>
                  new Promise((resolve, reject) => {
                    if (!oldData.isOrganizationAdmin) {
                      deleteUserOrganizaton(
                        oldData.uuid,
                        JSON.parse(secureLocalStorage.getItem("organizationID"))
                      ).then((res) => {
                        const dataDelete = [...data];
                        const index = oldData.tableData.id;
                        dataDelete.splice(index, 1);
                        setData([...dataDelete]);
                        resolve();
                        dispatch(
                          displayMessage("success", "User removed successfully")
                        );
                      });
                    } else {
                      resolve();
                      dispatch(
                        displayMessage(
                          "error",
                          "Can not remove the Admin of Team"
                        )
                      );
                    }
                  }),
              }}
            />
          </div>
          {/* </TabPanel> */}
        </>
      ) : (
        <h2>You must have an Enterprise Account to create a Team.</h2>
      )}
      <Backdrop open={isLoading} style={{ zIndex: 99999999999, color: "#fff" }}>
        <CircularProgress />
      </Backdrop>
    </div>
  );
};
function TabPanel(props) {
  const { children, value, index } = props;
  return <div>{value === index && children}</div>;
}
export default Organization;
